.content[data-v-4fb07134] {
  width: 100%;
  height: 100%;
}
.video[data-v-4fb07134] {
  width: 100%;
  height: 100%;
  position: relative;
}
.videolist[data-v-4fb07134] {
  width: 100%;
  height: 100%;
}
.video-direction[data-v-4fb07134] {
  width: 12.4031007752vh;
  height: 21.7054263566vh;
  position: absolute;
  bottom: 7.9069767442vh;
  right: 3.1007751938vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.video-direction .pictureZoom[data-v-4fb07134] {
  background-color: rgba(255, 255, 255, 0.186);
  width: 3.1007751938vh;
  height: 3.1007751938vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.video-direction .pictureZoom .zoom-color-icon[data-v-4fb07134] {
  color: #000;
}
.video-direction .close-btn[data-v-4fb07134] {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.5503875969vh;
  height: 1.5503875969vh;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.video-direction .close-btn .close-icon[data-v-4fb07134] {
  color: #fff;
  font-size: 0.7751937984vh;
}
.video-direction .video-direction-btn[data-v-4fb07134] {
  background-color: rgba(255, 255, 255, 0.186);
  border-radius: 50%;
  width: 12.4031007752vh;
  height: 12.4031007752vh;
}
.video-direction .video-direction-btn .direction-icon[data-v-4fb07134] {
  color: #000;
}
.video-direction .video-direction-btn .video-direction-btn-top[data-v-4fb07134] {
  height: 3.8759689922vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.video-direction .video-direction-btn .video-direction-btn-left_right[data-v-4fb07134] {
  height: 4.6511627907vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.video-direction .video-direction-btn .video-direction-btn-left_right .video-direction-central-circle[data-v-4fb07134] {
  width: 2.7131782946vh;
  height: 2.7131782946vh;
  border-radius: 50%;
  border: 1px solid rgba(222, 222, 222, 0.528);
  font-size: 0.6201550388vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.video-direction .video-direction-btn .video-direction-btn-bottom[data-v-4fb07134] {
  height: 3.8759689922vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.video-direction:hover .pictureZoom[data-v-4fb07134],
.video-direction:hover .video-direction-btn[data-v-4fb07134] {
  background-color: rgba(255, 255, 255, 0.5);
}