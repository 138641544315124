h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
.monitor-screen {
  width: 100vw;
  height: 100vh;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAEMwAAAUKAQMAAAA+k8h0AAAAA1BMVEUGBizHdYoNAAADQUlEQVR42uzBgQAAAACAoP2pF6kCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGD24EAAAAAAAMj/tRFUVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVYU9OBAAAAAAAPJ/bQRVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVWEPDgQAAAAAgPxfG0FVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVdiDAwEAAAAAIP/XRlBVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVFfbgQAAAAAAAyP+1EVRVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVhT04EAAAAAAA8n9tBFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVYQ8OBAAAAACA/F8bQVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV2IMDAQAAAAAg/9dGUFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVUV9uBAAAAAAADI/7URVFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVWFPTgQAAAAAADyf20EVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVpDw4EAAAAAAT5Ww9yBQAAAAAAAAAAAAAAAAAAAAAAAAAAAOwFnKQAAYcAu8MAAAAASUVORK5CYII=);
  background-size: contain;
  position: relative;
}
.monitor-screen .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100vw;
  height: 11.8604651163vh;
  background: url(../../static/img/header-bg.0d563ced.png) no-repeat 0 36px;
  background-size: contain;
}
.monitor-screen .header-title {
  letter-spacing: 0.0465116279vw;
  font-size: 1.2093023256vw;
  background-image: linear-gradient(92deg, #0072ff 0%, #00eaff 48.8525390625%, #01aaff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  padding-top: 32px;
}
.monitor-screen .content-container {
  position: absolute;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.monitor-screen .content-container .left-container {
  width: 23.0930232558vw;
  height: 100vh;
  background: url(../../static/img/left-bg.b62c246a.png) no-repeat;
  background-size: 23.0930232558vw 100vh;
}
.monitor-screen .content-container .left-container .earth-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 11.6279069767vh;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-title {
  position: relative;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-title img {
  width: 23.2558139535vw;
  height: 4.7286821705vh;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-title p {
  font-size: 0.8372093023vw;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 8.6976744186vw;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-title p span {
  font-size: 0.9302325581vw;
  font-weight: 700;
  color: #00ccff;
  margin: 0 0.2325581395vw;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-ball {
  position: relative;
  margin-top: 7.7519379845vh;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-ball img {
  width: 15.8604651163vw;
  height: 53.7209302326vh;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-ball span {
  position: absolute;
  font-size: 0.3720930233vw;
  color: #fafcfc;
  -webkit-text-stroke: 1px #f37d00;
  text-stroke: 1px #f37d00;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-ball .ns {
  left: 3.023255814vw;
  top: 15.503875969vh;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-ball .as {
  right: 3.7209302326vw;
  top: 18.6046511628vh;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-ball .eu {
  right: 6.511627907vw;
  top: 21.7054263566vh;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-ball .af {
  right: 6.7441860465vw;
  bottom: 21.7054263566vh;
  font-weight: 700;
  font-size: 0.5581395349vw;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-ball .oc {
  right: 1.3953488372vw;
  bottom: 19.3798449612vh;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-ball .sa {
  left: 3.9534883721vw;
  bottom: 15.503875969vh;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-desc {
  width: 17.4418604651vw;
  margin-top: 3.1007751938vh;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-desc .earth-content-desc-container div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-desc .earth-content-desc-container p {
  min-width: 120px;
  width: 4.6511627907vw;
  font-size: 0.3720930233vw;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-desc .earth-content-desc-container p span {
  font-size: 0.5581395349vw;
  margin: 0 0.2325581395vw;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-desc .earth-content-desc-container p.square:before {
  display: inline-block;
  content: "";
  width: 17px;
  height: 17px;
  vertical-align: top;
  margin-right: 0.2325581395vw;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-desc .earth-content-desc-container p.as-bg:before {
  background: #1969fd;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-desc .earth-content-desc-container p.af-bg:before {
  background: #61faf1;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-desc .earth-content-desc-container p.eu-bg:before {
  background: #02f7b5;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-desc .earth-content-desc-container p.sa-bg:before {
  background: #b43ff7;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-desc .earth-content-desc-container p.na-bg:before {
  background: #1496f1;
}
.monitor-screen .content-container .left-container .earth-content .earth-content-desc .earth-content-desc-container p.oc-bg:before {
  background: #c1f73f;
}
.monitor-screen .content-container .right-container {
  width: 23.0930232558vw;
  height: 100vh;
  background: url(../../static/img/left-bg.b62c246a.png) no-repeat;
  background-size: cover;
}
.monitor-screen .content-container .right-container .earth-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 11.6279069767vh;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-title {
  position: relative;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-title img {
  width: 23.2558139535vw;
  height: 4.7286821705vh;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-title p {
  font-size: 0.8372093023vw;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 8.6976744186vw;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-title p span {
  font-size: 0.9302325581vw;
  font-weight: 700;
  color: #00ccff;
  margin: 0 0.2325581395vw;
}
.monitor-screen .content-container .right-container .earth-content .earth-map {
  position: relative;
  margin-top: 7.7519379845vh;
}
.monitor-screen .content-container .right-container .earth-content .earth-map img {
  width: 19.1395348837vw;
  height: 54.2635658915vh;
}
.monitor-screen .content-container .right-container .earth-content .earth-map span {
  position: absolute;
  font-size: 0.3720930233vw;
  color: #fafcfc;
  -webkit-text-stroke: 1px #f37d00;
  text-stroke: 1px #f37d00;
}
.monitor-screen .content-container .right-container .earth-content .earth-map .ns {
  left: 6.511627907vw;
  top: 14.7286821705vh;
  font-size: 0.5581395349vw;
}
.monitor-screen .content-container .right-container .earth-content .earth-map .as {
  right: 5.5813953488vw;
  top: 17.0542635659vh;
}
.monitor-screen .content-container .right-container .earth-content .earth-map .eu {
  right: 2.3255813953vw;
  top: 10.8527131783vh;
}
.monitor-screen .content-container .right-container .earth-content .earth-map .af {
  right: 5.9302325581vw;
  bottom: 24.6511627907vh;
}
.monitor-screen .content-container .right-container .earth-content .earth-map .oc {
  right: 3.6279069767vw;
  bottom: 21.0852713178vh;
}
.monitor-screen .content-container .right-container .earth-content .earth-map .ws {
  left: 10.4651162791vw;
  bottom: 21.0852713178vh;
}
.monitor-screen .content-container .right-container .earth-content .earth-map .sa {
  right: 6.0465116279vw;
  bottom: 15.503875969vh;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc {
  width: 17.4418604651vw;
  margin-top: 1.5503875969vh;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc .earth-content-desc-container div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc .earth-content-desc-container p {
  min-width: 120px;
  width: 4.6511627907vw;
  font-size: 0.3720930233vw;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc .earth-content-desc-container p span {
  font-size: 0.5581395349vw;
  margin: 0 0.2325581395vw;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc .earth-content-desc-container p.square:before {
  display: inline-block;
  content: "";
  width: 17px;
  height: 17px;
  vertical-align: top;
  margin-right: 0.2325581395vw;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc .earth-content-desc-container p.as-bg:before {
  background: #61faf1;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc .earth-content-desc-container p.af-bg:before {
  background: #0264f7;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc .earth-content-desc-container p.eu-bg:before {
  background: #bf2ffb;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc .earth-content-desc-container p.sa-bg:before {
  background: #6319fd;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc .earth-content-desc-container p.na-bg:before {
  background: #d7fe62;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc .earth-content-desc-container p.oc-bg:before {
  background: #39f859;
}
.monitor-screen .content-container .right-container .earth-content .earth-content-desc .earth-content-desc-container p.ws-bg:before {
  background: #7c4afb;
}
.monitor-screen .content-container .center-container {
  width: 53.8139534884vw;
  height: 100vh;
}
.monitor-screen .content-container .center-container .map-title {
  width: 8.0930232558vw;
  height: 10.0775193798vh;
  background: url(../../static/img/square.c3f9cbbd.png) no-repeat;
  background-size: 8.0930232558vw 10.0775193798vh;
  margin-top: 2.6356589147vh;
  color: #62e0f2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.6046511628vw;
  line-height: 2.015503876vh;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.monitor-screen .content-container .center-container .map-title span {
  display: inline-block;
  margin-bottom: 0.7751937984vh;
}
.monitor-screen .content-container .center-container .map-container {
  height: 69.5348837209vh;
}
.monitor-screen .content-container .center-container .map-container .amap-marker-label {
  padding: 0;
  background: transparent;
  border: none;
}
.monitor-screen .content-container .center-container .map-container .map-box {
  width: 53.8139534884vw;
  height: 69.5348837209vh;
}
.monitor-screen .content-container .center-container ::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}
.monitor-screen .content-container .center-container ::-webkit-scrollbar-thumb {
  background-color: #061855;
}
.monitor-screen .content-container .center-container .company-list-container {
  margin-top: 2.3255813953vh;
  width: 53.8139534884vw;
  overflow-x: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.monitor-screen .content-container .center-container .company-list-container .company-list-item {
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 0.3488372093vw;
  margin-left: 0.3488372093vw;
}
.monitor-screen .content-container .center-container .company-list-container .company-list-item:hover {
  cursor: pointer;
}
.monitor-screen .content-container .center-container .company-list-container .company-list-item .company-list-item-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 3.7209302326vw;
  height: 10.2325581395vh;
  background: url(../../static/img/company-icon-box.96d1fdda.png) no-repeat;
  background-size: 3.7209302326vw 10.2325581395vh;
}
.monitor-screen .content-container .center-container .company-list-container .company-list-item .company-list-item-top span {
  display: inline-block;
  font-size: 0.8372093023vw;
  color: #00eaff;
  margin-bottom: 2.7906976744vh;
}
.monitor-screen .content-container .center-container .company-list-container .company-list-item p {
  margin-top: 0.9302325581vh;
  font-size: 0.6976744186vw;
  color: #00eaff;
  width: 3.7209302326vw;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.monitor-screen .content-container .right-container {
  width: 23.0930232558vw;
  height: 100vh;
  background: url(../../static/img/right-bg.f6c26989.png) no-repeat;
  background-size: cover;
}
.monitor-screen .marker-info {
  width: 5.3023255814vw;
  height: 13.2558139535vh;
  background: url(../../static/img/marker-card.3c4da86c.png) no-repeat;
  background-size: 5.3023255814vw 13.2558139535vh;
}
.monitor-screen .marker-info h5 {
  font-weight: 700;
  color: #ffffff;
  font-size: 0.3255813953vw;
  padding: 0.6976744186vh 0.3488372093vw;
}
.monitor-screen .marker-info img {
  width: 4.488372093vw;
  height: 8.5271317829vh;
  padding: 0.7751937984vh 0.3953488372vw;
}
.monitor-screen .scroll {
  width: 53.488372093vw;
  margin: 0 auto;
  height: 16.2790697674vh;
  overflow: hidden;
}
@media (max-width: 1920px) {
.earth-content-ball img {
    height: 309px !important;
}
.earth-content-ball .ns {
    left: 60px !important;
    top: 100px !important;
}
.earth-content-ball .as {
    top: 130px !important;
    right: 60px !important;
}
.earth-content-ball .eu {
    top: 120px !important;
}
.earth-content-ball .af {
    bottom: 120px !important;
}
.earth-content-ball .oc {
    bottom: 100px !important;
}
.earth-content-ball .sa {
    bottom: 100px !important;
}
.earth-map img {
    height: 312px !important;
}
.earth-map .ns {
    top: 100px !important;
}
.earth-map .as {
    top: 85px !important;
    right: 100px !important;
}
.earth-map .eu {
    top: 50px !important;
    right: 30px !important;
}
.earth-map .af {
    top: 165px !important;
    right: 100px !important;
}
.earth-map .oc {
    right: 60px !important;
    bottom: 120px !important;
}
.earth-map .ws {
    top: 160px !important;
    left: 150px !important;
}
.earth-map .sa {
    top: 210px !important;
    right: 100px !important;
}
.earth-content-desc-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.earth-content-desc-container div {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
}
.earth-content-desc-container p {
    line-height: 3;
}
.ml {
    margin-left: 64px;
}
.company-list-item-top {
    height: 60px !important;
    background-size: contain !important;
}
.company-list-item .company-list-item-top span {
    margin-bottom: 20px !important;
}
}