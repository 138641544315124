h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
.project-map .left-section {
  width: 16.2790697674vw;
  height: 100vh;
  margin-left: 0.9302325581vw;
}
.project-map .left-section .back-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #74e9f7;
  font-size: 1.2093023256vw;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.project-map .left-section .back-btn:hover {
  cursor: pointer;
}
.project-map .left-section .header-title {
  padding-top: 0;
}
.project-map .left-section .project-container .card {
  width: 15.5813953488vw;
  height: 37.984496124vh;
  background: url(../../static/img/card-bg.83a1ad9b.png) no-repeat;
  background-size: 15.5813953488vw 37.984496124vh;
  margin-bottom: 6.2015503876vh;
}
.project-map .left-section .project-container .card:nth-of-type(1) {
  margin-top: 2.3255813953vh;
}
.project-map .left-section .project-container .card .card-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.project-map .left-section .project-container .card .card-title .card-title-name {
  letter-spacing: 0.1395348837vw;
  font-size: 0.6511627907vw;
  font-weight: 700;
  color: #ffffff;
  margin-left: 1.8604651163vw;
}
.project-map .left-section .project-container .card .card-title .card-title-quantity {
  font-size: 0.6511627907vw;
  font-weight: 500;
  color: #03ddf4;
  margin-right: 0.4651162791vw;
}
.project-map .left-section .project-container .card .card-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0 0.488372093vw;
  margin: 3.2558139535vh 0;
  max-height: 31.7829457364vh;
  overflow-y: scroll;
}
.project-map .left-section .project-container .card .card-content .card-content-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ffffff;
  padding: 1.3953488372vh 0.488372093vw;
  position: relative;
}
.project-map .left-section .project-container .card .card-content .card-content-item:hover {
  cursor: pointer;
}
.project-map .left-section .project-container .card .card-content .card-content-item.active {
  background: rgba(0, 122, 255, 0.3);
  border: 2px solid #007aff;
}
.project-map .left-section .project-container .card .card-content .card-content-item .card-content-item-title {
  font-size: 0.5581395349vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.project-map .left-section .project-container .card .card-content .card-content-item .card-content-item-title.online {
  color: #ffffff;
}
.project-map .left-section .project-container .card .card-content .card-content-item .card-content-item-title.online:before {
  width: 0.488372093vw;
  height: 1.8604651163vh;
  display: inline-block;
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAYCAYAAAAVibZIAAAAAXNSR0IArs4c6QAABFNJREFUSEuNlX1oVXUYx7/P73fupvecWVNL25x5z9kofIsuUmloFAQlRFlphUGzpCiaIIkZhEX+o9gLaS/YCyGZlDiUCgtKqX9CAzN8IYXdc7cU3zbb0HvO5ry/3zfO1c1td8t+/53v83s+nPM83+c5guEOKToXPwCFBSTniKAGRCXBkwIcgshOk3abMVGi4dJlqOiE3fMs7XsCZksx4iSUtAHsJVkronyQiiKnBFhtfPezoYxBUJWPl4nlOyAJJZtMyvkEdZWHBiUdi2tVCk+JtasgGAfBFmPcpWiQi333+qEqjJuEdgMhLdbaR9BQdaQfduz8eFBX4Jb0GYiYkn6wq1q7zlcAHgSk2fjphRBhEipBnZbzcyj6FwJ/29HmLtSM6UBbV7VTdFZZ4ulSTS+fCwC+M+K8BX/UMZBat8bbYPkoBSut763vh+pcYS+ArEmlsphcebii7eK0YvHSLgEmD9tIkW4Y22gaqrbhyFlPjXIPCDjBpN0AN0m7OK3d99GY3YB8bAL3JRznWNUbHxBBHcizAG4AoMrh0is09xbrx/ymw3gRaL+hcI31q1aLbok2QviyEdwG3zvohPHblnzY0i5AfdVh5AsTNfE1iHuGggnst4E3C9uodTY6TSWnrO/OFBVGfwox3gTupMQqOh+fArHEBO6uq42Ka7Vjj/f1YCDcADMReId0GG8B7WLjuGNF5wrtgBw1gTsX+c4p2qbyplLVYVL6xMBkHRY6QIwrK4PSjSYzerMTRmtIvm6c1PQE2gPIHhO489FyYboWSXy52ATe1n5A24WpuihXLTaATFFN1k9/oHKFVwVYq5SanUCPk2i39V4WbazWxegcgA4oed546d2IumcoYz8VYOqwTlCywGTcnU6u8C6B5Sbl1CfQnwHMMaPd8aiRWIfRPpB3DAsoF3uNujQBmeouHRZ+BTHL+G61qJbCShGsg3Ch8au261z8GGC3/y+oyEbju8sQFiZoygmAP5nAmy84GtXoFHIEj1rfzSajpnPR5wCf/S8wgYO2J74b024sOGHhfRLLAPW4CdLNpTHVYbQBZBOB12zgrU2s5eQKayhqBcCKMjj5rUmZRtx8facTds+lNbsp+Mt+6d6ON8VeXiin6aoo+kNEAlg+Z+q9zSW9tSujjPOEJF4EKwTSUlRqBzLpfUk41RpljeEPAhljhHcmw9M/+yVArqdBobhHRGph8aG5WHwD0677Z9gSkI7Kxy8IsA5kClRPmvr0jr67g5d0vnuKpt0KcjYEnbBshugfFdh2SdirrdSKcJ4VWSSkT+KkEjxTDLzEQf2nbPMn60y1xkth7SsCaRihWZ0CbCpWmPWoK/+acmgfhRTke2doFJtALk1kQvYra1cUnY69yGR6RnLHyNArGSoXvSjgR1cevzeB99C1PDz0H7VcrL1/YBKBjAC3ljTiHAS/D4bKGRO4S0asqc5FXwBsvNabDIqLtBrfzQzU/gWcvPoxZ52YkAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 0.488372093vw 1.8604651163vh;
  margin-right: 0.4651162791vw;
}
.project-map .left-section .project-container .card .card-content .card-content-item .card-content-item-title.offline {
  color: #aaaaaa;
}
.project-map .left-section .project-container .card .card-content .card-content-item .card-content-item-title.offline:before {
  width: 0.488372093vw;
  height: 1.8604651163vh;
  display: inline-block;
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAYCAYAAAAVibZIAAAAAXNSR0IArs4c6QAAAwxJREFUSEuNlVuoVWUUhb/hBURUKi+pKAkZIQmJPkihiBgZUVYoVCJkWongHY+3F1OURKQ0QhBKyzrlQ/h0IBUURfKOqC9q+OAtBAm1o9KVRozDvw/7LLcc59Pe6//nWHOOOeZYokHYFjAaeAEYATwBdAduAZeBY8ARSX82yk9yh7A9EvgAeLocBOgm8G8BHwQk7zbwnaR9VYwOoLanALMAA3uBPZJSWXvY7gtMAKYCfYCDwOeS/qldage1/TrwEXADWCfpau2S7SR3A+5I+i/PbfcClgJjChWfdAC1Hd7yMG0uldRakqYBE0vbyfkDOAH8IOlX212AFYX7HZJ251JbpbY3FQ4XSbpi+ylgNdC/0SCAv4HNkg7b7pH2gceADyX9LtvPp13gJ0lbbfcGthTAO4W3VFSNDG6VpPO2xwPLgF2SmgM6B3gNmJ+h2J4NjAXWl6ofLwlRRTUuSVpcaPg2ipA0L6ApvY+kmeVwZ2ntVN2gMvEdNboqyPPKyzO0qOLdgDYD1yUttz0A+Ap4X9JvFSl9D4SaaoTb/bZnAG8DbZVmYuckfVwG9AWwSdKhukqHAlsfMrRtklpsRynvAU0BTVutkhYWGaWiViDgZ4Fh4RsIcKMI98dsZwvfiNYDmslHp9Ml/WX7U+CZhwA0UsAMSfdtbwCGA+8ENOs2E9gg6WfbLwIrHxG0RdI229Ho18CZ0BjQONCXwDUg4rfthcBLnQDHE5riVLaz3lnztsJqG1XT6jeSfizSyjTfKjtfxT9eZHfP9nNlea4DC1JUDTSrthmIrW2RdKCs75NAtiXDip/GbI5KuljOY49rgJ7Akpqj1bvU4GwR0A9oAZol3WtEge2uwCvRM5DfGyUdrd2t+mkqawKeBQJ4BDhd3Ct+mc3Kuo4DBpYvwWeSztS/vJHzxzwmA28Cqb5R5IV7gN2S7lYvPABat0U5C5cxm5fL80vAduBCvdM/Mmgd+KvA3PL/pKS1nWm4ymlaHlVJCs9DyrO0+kvlPJ+YKKc9qqCLgEmdVVI5vykpHtwe/wMk0UOJWvj8oAAAAABJRU5ErkJggg==) no-repeat;
  background-size: 0.488372093vw 1.8604651163vh;
  margin-right: 0.4651162791vw;
}
.project-map .left-section .project-container .card .card-content .card-content-item .card-content-item__online {
  width: 0.2790697674vw;
  height: 0.9302325581vh;
  background: #15db6d;
  border-radius: 50%;
  position: absolute;
  right: 0;
}
.project-map .left-section .project-container .card .card-content .card-content-item .card-content-item__offline {
  width: 0.2790697674vw;
  height: 0.9302325581vh;
  background: #aaaaaa;
  border-radius: 50%;
  position: absolute;
  right: 0;
}
.project-map .right-section {
  width: 82.7906976744vw;
  height: 100vh;
  position: relative;
}
.project-map .right-section .camera-list-container {
  width: 9.3255813953vw;
  height: 100%;
  background-color: #083863;
  background-size: 9.3255813953vw 81.7829457364vh;
  position: absolute;
  top: 0;
  left: 0.3488372093vw;
  z-index: 2;
}
.project-map .right-section .camera-list-container .camera-list h5 {
  color: #ffffff;
  font-weight: 500;
  margin-left: 1vw;
  margin-top: 1.2403100775vh;
}
.project-map .right-section .camera-list-container .video-name {
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #083863;
}
.project-map .right-section .camera-list-container .video-name .video-name-list_item {
  margin-top: 0.3875968992vh;
  padding: 0 0.4651162791vw;
  background-color: rgba(255, 255, 255, 0.041);
}
.project-map .right-section .camera-list-container .video-name .video-name-list_item .video-mouse-H {
  position: relative;
  height: 4.3410852713vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.2403100775vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.project-map .right-section .camera-list-container .video-name .video-name-list_item .video-mouse-H .item_status_1 {
  color: #15db6d;
  display: inline-block;
  height: 4.3410852713vh;
  line-height: 4.3410852713vh;
}
.project-map .right-section .camera-list-container .video-name .video-name-list_item .video-mouse-H .item_status_2 {
  color: #aaa;
  display: inline-block;
  height: 4.3410852713vh;
  line-height: 4.3410852713vh;
}
.project-map .right-section .camera-list-container .video-name .video-name-list_item .video-mouse-H .item_name_1 {
  color: #fff;
  display: inline-block;
  height: 4.3410852713vh;
  line-height: 4.3410852713vh;
}
.project-map .right-section .camera-list-container .video-name .video-name-list_item .video-mouse-H .item_name_2 {
  color: #aaa;
  display: inline-block;
  height: 4.3410852713vh;
  line-height: 4.3410852713vh;
}
.project-map .right-section .camera-list-container .video-name .video-name-list_item .video-mouse-H .video-name-list_item-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
.project-map .right-section .camera-list-container .video-name .video-name-list_item .video-mouse-H .video-name-list_item-btn button {
  color: #ffffff;
  border: 0;
  background: rgba(0, 122, 255, 0.7);
  padding: 0 0.2325581395vw;
  margin-left: 0.2325581395vw;
  font-size: 0.4186046512vw;
  font-weight: 700;
}
.project-map .right-section .camera-list-container .video-name .video-name-list_item .justify-content {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.project-map .right-section .camera-list-container .contact-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  bottom: 2.3255813953vh;
  width: 9.3255813953vw;
}
.project-map .right-section .camera-list-container .contact-img img {
  width: 7.9534883721vw;
  height: 4.8062015504vh;
}
.project-map .right-section .big-video {
  width: 40.3953488372vw;
  height: 0;
  position: absolute;
  top: 6.976744186vh;
  left: 10.9534883721vw;
  z-index: 2;
}
.project-map .right-section .big_h {
  height: 81.7829457364vh !important;
}
.project-map .right-section .small-video {
  position: absolute;
  top: 0;
  width: calc(100% - 10.4651162791vw);
  left: 10.4651162791vw;
  z-index: 2;
  height: 100%;
}
.project-map .right-section .small-video .top-video {
  height: 100%;
}
.project-map .right-section .small-video .top-video ul,
.project-map .right-section .small-video .top-video li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.project-map .right-section .small-video .top-video .video-title {
  padding-left: 1.0465116279vw;
  line-height: 4.4186046512vh;
  color: #fff;
  font-size: 1.3953488372vh;
}
.project-map .right-section .small-video .top-video .video-list {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.project-map .right-section .small-video .top-video .video-list li {
  margin-bottom: 1.9379844961vh;
  background: #000;
  opacity: 0.8;
  width: 48%;
  height: 48%;
}
.project-map .right-section .small-video .bottom-text {
  margin-top: 2.3255813953vh;
  width: 29.0697674419vw;
  height: 25.1937984496vh;
  background-image: url(data:image/png;base64,);
  background-size: 96.8992248062vh 25.1937984496vh;
  background-repeat: no-repeat;
}
.project-map .right-section .small-video .bottom-text .video-title {
  padding-left: 1.0465116279vw;
  line-height: 4.4186046512vh;
  color: #fff;
  font-size: 0.4186046512vw;
}
.project-map .right-section .small-video .bottom-text .el-col {
  line-height: 4.6511627907vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.project-map .right-section .small-video .bottom-text .el-col label {
  padding-left: 0.9302325581vw;
  margin-right: 0.2325581395vw;
  min-width: 2.3255813953vw;
  font-size: 1.3953488372vh;
  text-align: end;
  color: #bbc6d9;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.project-map .right-section .small-video .bottom-text .el-col span {
  font-size: 1.3953488372vh;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.project-map .right-section .small-video .bottom-text .el-col > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-map .right-section .small-video .bottom-text .green-style {
  color: #15db6d !important;
}
.project-map .right-section #project-map-container {
  height: 100%;
  width: 83.9534883721vw;
}
.marker-class {
  width: 1.9534883721vw;
  height: 10.5426356589vh;
}
.marker-name {
  color: #03ddf4;
  font-size: 0.511627907vw;
  font-weight: 500;
  margin: 4px 0 0 0;
  width: 30vw;
}