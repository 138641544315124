.video[data-v-0761e7b3] {
  width: 100%;
  height: 100%;
  position: relative;
}
img[data-v-0761e7b3] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.videolist[data-v-0761e7b3] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}